/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const CreateUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    CreateUser(input: $input)
  }
`;
export const ActivateOrDeactivateUser = /* GraphQL */ `
  mutation ActivateOrDeactivateUser($input: ActivateOrDeactivateUserInput) {
    ActivateOrDeactivateUser(input: $input)
  }
`;
export const UpdateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails($input: UpdateUserDetailsInput) {
    UpdateUserDetails(input: $input)
  }
`;
export const IssueCredential = /* GraphQL */ `
  mutation IssueCredential($input: IssueCredentialInput) {
    IssueCredential(input: $input)
  }
`;
export const IssueCredentialWithoutDoc = /* GraphQL */ `
  mutation IssueCredentialWithoutDoc($input: IssueCredentialWithoutDocInput) {
    IssueCredentialWithoutDoc(input: $input)
  }
`;
export const IssueCredentailWithTemplate = /* GraphQL */ `
  mutation IssueCredentailWithTemplate(
    $input: IssueCredentailWithTemplateInput
  ) {
    IssueCredentailWithTemplate(input: $input)
  }
`;
export const TransferOwnership = /* GraphQL */ `
  mutation TransferOwnership($input: TransferOwnershipInput) {
    TransferOwnership(input: $input)
  }
`;
export const ActivateOrDeactivateTemplate = /* GraphQL */ `
  mutation ActivateOrDeactivateTemplate(
    $input: ActivateOrDeactivateTemplateInput
  ) {
    ActivateOrDeactivateTemplate(input: $input)
  }
`;
export const UpdateIssuerFormDetails = /* GraphQL */ `
  mutation UpdateIssuerFormDetails($input: UpdateIssuerFormDetailsInput) {
    UpdateIssuerFormDetails(input: $input)
  }
`;
export const UpdateIssuerDetails = /* GraphQL */ `
  mutation UpdateIssuerDetails($input: UpdateIssuerDetailsInput) {
    UpdateIssuerDetails(input: $input)
  }
`;
export const CreatePDFTemplate = /* GraphQL */ `
  mutation CreatePDFTemplate($input: CreatePDFTemplateInput) {
    CreatePDFTemplate(input: $input)
  }
`;
export const UpdatePDFTemplate = /* GraphQL */ `
  mutation UpdatePDFTemplate($input: UpdatePDFTemplateInput) {
    UpdatePDFTemplate(input: $input)
  }
`;
export const DeletePDFTemplate = /* GraphQL */ `
  mutation DeletePDFTemplate($input: DeletePDFTemplateInput) {
    DeletePDFTemplate(input: $input)
  }
`;
export const UpdateIssuerURLsAndAPIId = /* GraphQL */ `
  mutation UpdateIssuerURLsAndAPIId($input: UpdateIssuerURLsAndAPIIdInput) {
    UpdateIssuerURLsAndAPIId(input: $input)
  }
`;
export const DeleteSignatureURL = /* GraphQL */ `
  mutation DeleteSignatureURL($input: DeleteSignatureURLInput) {
    DeleteSignatureURL(input: $input)
  }
`;
export const CreateMedia = /* GraphQL */ `
  mutation CreateMedia($input: CreateMediaInput) {
    CreateMedia(input: $input)
  }
`;
export const ActivateOrDeactivateMedia = /* GraphQL */ `
  mutation ActivateOrDeactivateMedia($input: ActivateOrDeactivateMediaInput) {
    ActivateOrDeactivateMedia(input: $input)
  }
`;
export const IssueCredentialsInBulk = /* GraphQL */ `
  mutation IssueCredentialsInBulk($input: IssueCredentialsInBulkInput) {
    IssueCredentialsInBulk(input: $input)
  }
`;
export const ApproveOrRejectVerificationRequest = /* GraphQL */ `
  mutation ApproveOrRejectVerificationRequest(
    $input: ApproveOrRejectVerificationRequestInput
  ) {
    ApproveOrRejectVerificationRequest(input: $input)
  }
`;
export const RLPostSignUp = /* GraphQL */ `
  mutation RLPostSignUp($input: RLPostSignUpInput) {
    RLPostSignUp(input: $input)
  }
`;
export const RLUpdateIssuerDetails = /* GraphQL */ `
  mutation RLUpdateIssuerDetails($input: RLUpdateIssuerDetailsInput) {
    RLUpdateIssuerDetails(input: $input)
  }
`;
export const RLCheckNewDocument = /* GraphQL */ `
  mutation RLCheckNewDocument($input: RLCheckNewDocumentInput) {
    RLCheckNewDocument(input: $input)
  }
`;
export const RLFileConversion = /* GraphQL */ `
  mutation RLFileConversion($input: RLFileConversionInput) {
    RLFileConversion(input: $input)
  }
`;
export const RLCreateUser = /* GraphQL */ `
  mutation RLCreateUser($input: RLCreateUserInput) {
    RLCreateUser(input: $input)
  }
`;
export const UpgradeToAdmin = /* GraphQL */ `
  mutation UpgradeToAdmin($input: UpgradeToAdminInput) {
    UpgradeToAdmin(input: $input)
  }
`;
export const DowngradeToMember = /* GraphQL */ `
  mutation DowngradeToMember($input: DowngradeToMemberInput) {
    DowngradeToMember(input: $input)
  }
`;
export const RL_addReasonOfLeaving = /* GraphQL */ `
  mutation RL_addReasonOfLeaving($input: RL_AddReasonOfLeavingInput) {
    RL_addReasonOfLeaving(input: $input)
  }
`;
export const UpdateIssuerNatureOfSeparation = /* GraphQL */ `
  mutation UpdateIssuerNatureOfSeparation(
    $input: UpdateIssuerNatureOfSeparationInput!
  ) {
    UpdateIssuerNatureOfSeparation(input: $input)
  }
`;
